import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import { createPinia } from "pinia"

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import 'animate.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAoPAjXku6J9nGAIDxJJpLS24Ru79IZviM",
  databaseURL: "https://asesor-27bd7-default-rtdb.europe-west1.firebasedatabase.app",
  authDomain: "asesor-27bd7.firebaseapp.com",
  projectId: "asesor-27bd7",
  storageBucket: "asesor-27bd7.appspot.com",
  messagingSenderId: "586913152264",
  appId: "1:586913152264:web:a550fb1ec824b1655340bd"
};
firebase.initializeApp(firebaseConfig)
const storageRef = firebase.storage().ref();
export { storageRef }

const pinia = createPinia()

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(pinia)
  
router.isReady().then(() => {
  app.mount('#app');
});