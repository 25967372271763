
import { ScreenOrientation  } from '@awesome-cordova-plugins/screen-orientation';
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  mounted(){
    ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
  }
});
