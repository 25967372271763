import { defineStore } from "pinia"

const alarmStore = defineStore("alarm", {
    state: () => ({
        newmsg: 0
    })
})

export {
    alarmStore
}