import { getDatabase, ref, set, get, remove, child, update } from "firebase/database"
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth"
import CryptoJS from "crypto-js"
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

const myimage = CryptoJS.AES.decrypt("U2FsdGVkX1+DnRSxsLfUgXTrNXZRvPhHDBGjflP7jNMNYxwHy1aWX3OrrkfL2g9v", 'dootaapp')
const myimagedeff = myimage.toString(CryptoJS.enc.Utf8)

const myimage1 = CryptoJS.AES.decrypt("U2FsdGVkX182xHHH0hkc9bv/hCzrOCcuLWkIF9apuNw=", 'dootaapp')
const myimagedeff1 = myimage1.toString(CryptoJS.enc.Utf8)

const firebaseConfig = {
    apiKey: "AIzaSyAoPAjXku6J9nGAIDxJJpLS24Ru79IZviM",
    databaseURL: "https://asesor-27bd7-default-rtdb.europe-west1.firebasedatabase.app",
    authDomain: "asesor-27bd7.firebaseapp.com",
    projectId: "asesor-27bd7",
    storageBucket: "asesor-27bd7.appspot.com",
    messagingSenderId: "586913152264",
    appId: "1:586913152264:web:a550fb1ec824b1655340bd"
};
const app = firebase.initializeApp(firebaseConfig)
const db = getDatabase()
const auth = getAuth()

const url = "https://asesor-27bd7-default-rtdb.europe-west1.firebasedatabase.app/"
const mailserver = "http://46.183.117.15:4000/enviarmail"

export async function baseConnect(where) {
    let datos = ""
    const dbref = ref(db)
    await get(child(dbref, where))
        .then(data => {
            datos = data.val()
        })
    return datos
}

export async function baseConnectPut(where, post) {
    await set(ref(db, where), post)
        .then(() => {
            console.log("Conexión realizada con éxito")
        })
        .catch(err => {
            console.log(err)
        })
}

export async function baseConnectDelete(where) {
    await remove(ref(db, where))
        .then(() => {
            console.log("Borrado correctamente")
        })
        .catch(err => {
            console.log(err)
        })
}

// eslint-disable-next-line
export async function enviarMail(where, asunto, cuerpo, footer) {
    let datos = ""
    await fetch(`${mailserver}?donde=${where}&asunto=${encodeURIComponent(asunto)}&cuerpo=${encodeURIComponent(cuerpo)}&footer=${encodeURIComponent(footer)}`)
        .then(() => {
            datos = "Email enviado"
        })
        .catch(err => {
            datos = "Error al enviar mail."
            console.log(err)
        })
    return datos
}

export async function ResetPassword(email) {
    console.log(email)
    let boolean = false
    await sendPasswordResetEmail(auth, email)
        .then(() => {
            boolean = true
        })
        .catch((error) => {
            console.log(error)
            const errorCode = error.code;
            const errorMessage = error.message;
            // ..
        });

    return boolean
}