<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div v-if="login === true"
                class="flex flex-col justify-center items-center h-full animate__animated animate__fadeIn">
                <div class="text-gray-700 mb-10">
                    <img src="../img/logo.png" alt="" class="w-52">
                </div>
                <form @submit="loginDeff($event)">
                    <div class="flex flex-col">
                        <label class="flex flex-row mb-2 items-center">
                            <span><i class="fa-solid fa-envelope text-lg btn"></i></span>
                            <input v-model="email" type="text" placeholder="miemail@miemail.com"
                                class="input input-bordered" />
                        </label>
                        <label class="flex flex-row mb-2 items-center">
                            <span><i class="fa-solid fa-key text-lg btn"></i></span>
                            <input v-model="pass" type="password" placeholder="*********" class="input input-bordered" />
                        </label>
                        <div class="mt-2">
                            <button onclick="reset_password.showModal()" type="button"
                                class="text-sm italic text-teal-500">¿Has olvidado la contraseña? <i
                                    class="fa-solid fa-user-lock"></i></button>
                        </div>
                    </div>
                    <button type="submit" class="btn w-full bg-teal-500 mt-8">Entrar
                        <i class="fa-solid fa-right-to-bracket ml-2"></i></button>
                    <button type="button" @click="register" class="btn bg-gray-700 text-white w-full mt-2">Registrarse <i
                            class="fa-solid fa-id-card ml-2"></i></button>
                </form>
            </div>
            <div v-if="login === false" class="flex flex-col my-10 items-center h-full animate__animated animate__fadeIn">
                <div class="text-gray-700 mb-10">
                    <img src="../img/logo.png" alt="" class="w-52">
                </div>
                <div>
                    <p class="text-teal-500 text-sm text-center mb-2">¡Empecemos!</p>
                    <div class="flex flex-col">
                        <label class="input-group mb-2 flex flex-row items-center">
                            <span><i class="fa-solid fa-user text-lg text-gray-700 btn"></i></span>
                            <input v-model="registername" type="text" placeholder="Daniel Lorenzo Giménez"
                                class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2 flex flex-row items-center">
                            <span><i class="fa-solid fa-envelope text-lg text-gray-700 btn"></i></span>
                            <input v-model="registeremail" type="text" placeholder="miemail@miemail.com"
                                class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2 flex flex-row items-center">
                            <span><i class="fa-solid fa-passport text-lg text-gray-700 btn"></i></span>
                            <input @keypress="txNombres($event)" v-model="registernie" type="text"
                                placeholder="Dni o pasaporte" class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2 flex flex-row items-center">
                            <span><i class="fa-solid fa-mobile btn"></i></span>
                            <input v-model="registernumber" type="text" placeholder="675890712"
                                class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2 flex flex-row items-center">
                            <span><i class="fa-solid fa-key btn"></i></span>
                            <input v-model="registerpass" type="password" placeholder="Contraseña"
                                class="input input-bordered" />
                        </label>
                        <label class="input-group mb-2 flex flex-row items-center">
                            <span><i class="fa-solid fa-key btn"></i></span>
                            <input v-model="registerpassrepeat" type="password" placeholder="Repetir contraseña"
                                class="input input-bordered" />
                        </label>
                    </div>
                    <button @click="registerDeff()" class="btn w-full bg-teal-500 mt-10">Registrarse <i
                            class="fa-solid fa-right-to-bracket ml-2"></i></button>
                    <button @click="register()"
                        class="btn w-full bg-gray-100 text-gray-700  mt-2 hover:bg-gray-300 mb-10"><i
                            class="fa-solid fa-arrow-left mr-2"></i> atrás</button>
                </div>
            </div>
            <dialog id="reset_password" class="modal">
                <div class="modal-box">
                    <h3 class="font-bold text-lg">Introduzca su correo electrónico</h3>
                    <div class="py-4 w-full">
                        <label class="flex flex-row mb-2 items-center w-full">
                            <span><i class="fa-solid fa-envelope text-lg btn"></i></span>
                            <input v-model="email" type="text" placeholder="Introducir email"
                                class="input input-bordered w-full" />
                        </label>
                        <label class="flex flex-row mb-2 items-center w-full">
                            <span><i class="fa-solid fa-passport text-lg btn"></i></span>
                            <input v-model="nie" type="text" placeholder="Introducir NIE/DNI"
                                class="input input-bordered w-full" />
                        </label>
                        <label class="flex flex-row mb-2 items-center w-full">
                            <span><i class="fa-solid fa-phone text-lg btn"></i></span>
                            <input v-model="phone" type="text" placeholder="Número de teléfono"
                                class="input input-bordered w-full" />
                        </label>
                    </div>
                    <div class="modal-action">
                        <form method="dialog">
                            <!-- if there is a button in form, it will close the modal -->
                            <button @click="sendMail" class="btn bg-teal-500 mr-2">Enviar correo de recuperación</button>
                            <button class="btn">Cerrar</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </ion-content>
    </ion-page>
</template>

<script>
import { Preferences } from '@capacitor/preferences';
import moment from "moment"
import { baseConnect, baseConnectPut, ResetPassword } from "../db/db"
import { IonPage, IonContent, toastController } from '@ionic/vue';
import { useRouter } from "vue-router"
export default {
    data() {
        return {
            passboolean: true,
            login: true,
            nie: "",
            phone: "",
            email: "",
            pass: "",
            registername: "",
            registernie: "",
            registeremail: "",
            registernumber: "",
            registerpass: "",
            registerpassrepeat: "",
            msg: "",
            router: useRouter()
        }
    },
    components: {
        IonPage,
        IonContent
    },
    methods: {
        register() {
            if (this.login === true) {
                this.login = false
            } else {
                this.login = true
            }
        },
        passbool() {
            if (this.passboolean === true) {
                this.passboolean = false
            } else {
                this.passboolean = true
            }
        },
        async registerDeff() {
            try {
                if (this.registernumber.length < 8 || this.registername === "" || this.registeremail.length < 6 || this.registerpass.length < 8 || this.registernie.length <= 8) {
                    this.presentToast("Porfavor, rellene los datos correctamente. 🤔")
                } else {
                    this.presentToast("Registrando, espere...")
                    let datos = await baseConnect("user")
                    let boolean = false
                    if (datos !== null) {
                        datos.forEach((element, index) => {
                            if (element.nie === this.registernie.toUpperCase() || element.email === this.registeremail.toLowerCase()) {
                                boolean = true
                                this.presentToast("DNI ya registrado. 🤔")
                            }
                            if (boolean === false) {
                                if (index === datos.length - 1) {
                                    let objeto = {
                                        id: datos.length,
                                        register: moment().format('L'),
                                        nie: this.registernie.toUpperCase(),
                                        name: this.registername,
                                        pass: this.registerpass,
                                        number: this.registernumber,
                                        email: this.registeremail.toLowerCase()
                                    }
                                    let conectar = async () => {
                                        await baseConnectPut(`user/${datos.length}`, objeto)
                                        this.presentToast("¡Registrado! 🥳")
                                        await Preferences.set({
                                            key: 'email',
                                            value: `${this.registeremail.toLowerCase()}`
                                        });
                                        await Preferences.set({
                                            key: 'index',
                                            value: `${datos.length}`
                                        });
                                        await Preferences.set({
                                            key: 'nie',
                                            value: `${this.registernie.toUpperCase()}`
                                        });
                                        await Preferences.set({
                                            key: 'name',
                                            value: `${this.registername}`
                                        });
                                        await Preferences.set({
                                            key: 'phone',
                                            value: `${this.registernumber}`
                                        });
                                        this.router.push("/inicio")
                                    }
                                    conectar()
                                }
                            }
                        });

                    } else {
                        let objeto = {
                            id: 0,
                            register: moment().format('L'),
                            nie: this.registernie.toUpperCase(),
                            name: this.registername,
                            pass: this.registerpass,
                            number: this.registernumber,
                            email: this.registeremail.toLowerCase()
                        }
                        let conectar = async () => {
                            await baseConnectPut(`user/0`, objeto)
                            this.presentToast("¡Registrado! 🥳")
                            await Preferences.set({
                                key: 'email',
                                value: `${this.registeremail.toLowerCase()}`
                            });
                            await Preferences.set({
                                key: 'index',
                                value: `${0}`
                            });
                            await Preferences.set({
                                key: 'nie',
                                value: `${this.registernie.toUpperCase()}`
                            });
                            await Preferences.set({
                                key: 'name',
                                value: `${this.registername}`
                            });
                            await Preferences.set({
                                key: 'phone',
                                value: `${this.registernumber}`
                            });
                            this.router.push("/inicio")
                        }
                        conectar()
                    }
                }
            } catch (error) {
                this.presentToast("Hubo un problema al registrarse, contacte con el administrador. 🤔")
            }
        },
        async loginDeff(e) {
            e.preventDefault()
            try {
                if (this.email === "" || this.pass === "") {
                    this.presentToast("Rellene todos los campos. 😔")
                } else {
                    let boolean = true
                    this.presentToast("Verificando datos.")
                    let datos = await baseConnect("user")
                    console.log(datos)
                    datos.forEach(async (element, index) => {
                        if (element.pass === this.pass && element.email === this.email) {
                            boolean = false
                            this.presentToast("¡Entrando! 🥳")
                            await Preferences.set({
                                key: 'email',
                                value: `${element.email.toLowerCase()}`
                            });
                            await Preferences.set({
                                key: 'index',
                                value: `${index}`
                            });
                            await Preferences.set({
                                key: 'nie',
                                value: `${element.nie.toUpperCase()}`
                            });
                            await Preferences.set({
                                key: 'name',
                                value: `${element.name}`
                            });
                            await Preferences.set({
                                key: 'phone',
                                value: `${element.number}`
                            });
                            this.router.push("/inicio")
                        } else {
                            if (index === (datos.length - 1)) {
                                if (boolean === true) {
                                    this.presentToast("Correo y contraseña no encontrados. 😔")
                                }
                            }
                        }
                    })
                }
            } catch (error) {
                this.presentToast("Error de conexión. 😔")
            }

        },
        async presentToast(msg) {
            const toast = await toastController.create({
                message: msg,
                duration: 2000,
                position: "top"
            });

            await toast.present();
        },
        async sendMail() {
            const response = await baseConnect("user")
            if (response !== false || response !== null || response !== undefined) {
                let booleanCo = false
                response.forEach(element => {
                    if (this.email === element.email && this.nie.toUpperCase() === element.nie && element.number === this.phone) {
                        booleanCo = true
                        this.presentToast("Contraseña: " + element.pass)
                    }
                })

                if (booleanCo === false) {
                    this.presentToast("Este usuario no existe. 😔")
                }
            } else {
                this.presentToast("Este correo no existe. 😔")
            }
        },
        txNombres(event) {
            if ((event.keyCode != 32) && (event.keyCode < 48) || (event.keyCode > 57) && (event.keyCode < 65) || (event.keyCode > 90) && (event.keyCode < 97) || (event.keyCode > 122))
                event.returnValue = false;
        },
        goBack() {
            this.router.push("/pass")
        }
    }
}
</script>

<style></style>